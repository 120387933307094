import React from "react"
import Navigation from "./navigation"
import Container from "./container"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

export default props => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (
    <div>
      <Helmet>
        <meta charSet={"utf-8"} />
        <title>{data.site.siteMetadata.title}</title>
        <meta
          name={"description"}
          content={"This is a page from the tutorials of Gatsby"}
        />
      </Helmet>
      <Navigation current={props.current} />
      <Container>
        <h4>{data.site.siteMetadata.title}</h4>
        {props.children}
      </Container>
    </div>
  )
}
