import React from "react"
import { Link } from "gatsby"

export default props => (
  <nav>
    <ul>
      <li className={"" + (props.current === "home" && "active") || ""}>
        <Link to={"/"}>Home</Link>
      </li>
      <li className={"" + (props.current === "about" && "active") || ""}>
        <Link to={"/about/"}>About</Link>
      </li>
      <li
        className={
          "" + (props.current === "about-css-modules" && "active") || ""
        }
      >
        <Link to={"/about-css-modules/"}>About CSS Modules</Link>
      </li>
      <li className={"" + (props.current === "my-files" && "active") || ""}>
        <Link to={"/my-files/"}>My Files</Link>
      </li>
      <li className={"" + (props.current === "address-book" && "active") || ""}>
        <Link to={"/address-book/"}>Address Book</Link>
      </li>
      <li className={"" + (props.current === "chat" && "active") || ""}>
        <Link to={"/chat/"}>Chat</Link>
      </li>
    </ul>
  </nav>
)
